/* eslint-disable react/no-danger -- when updating this component, consider replacing dangerous jsx properties */
import type { LegacyRef, MouseEventHandler, ReactNode } from 'react';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@compiled/react';
import { defineMessages, FormattedMessage } from 'react-intl-next';

import { Box, Flex, xcss } from '@atlaskit/primitives';
import { DropdownItem, type DropdownItemProps } from '@atlaskit/dropdown-menu';
import Badge from '@atlaskit/badge';
import Lozenge from '@atlaskit/lozenge';

const i18n = defineMessages({
	lozengeBetaText: {
		id: 'content-tools.item.beta.text',
		defaultMessage: 'Beta',
		description: 'label indicating that the item in content tools is a beta feature',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	display: 'flex',
});

const labelContainerStyles = xcss({
	flex: 2,
});

const badgeContainerStyles = xcss({
	paddingLeft: 'space.075',
});

class ContentToolsItemClass extends PureComponent<ContentToolsItemClassProps> {
	static propTypes = {
		onClick: PropTypes.func,
		className: PropTypes.string,
		id: PropTypes.string.isRequired,
		testId: PropTypes.string,
		url: PropTypes.string,
		label: PropTypes.node.isRequired,
		isDisabled: PropTypes.bool,
		innerRef: PropTypes.object,
		linkComponent: PropTypes.elementType,
		onMouseEnter: PropTypes.func,
	};

	static defaultProps = {
		onMouseEnter: () => {},
	};

	render() {
		const {
			label,
			onClick,
			url,
			id,
			className,
			isDisabled,
			innerRef,
			testId,
			onMouseEnter,
			showBetaLozenge,
		} = this.props;

		return (
			<div onMouseEnter={onMouseEnter} data-test-id={id}>
				<DropdownItem
					// @ts-ignore DropdownItem doesn't declare support for className
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					className={className}
					id={id}
					href={url ?? undefined}
					testId={(testId || id) ?? undefined}
					onClick={onClick}
					isDisabled={isDisabled}
					UNSAFE_shouldDisableRouterLink
				>
					{typeof label === 'string' ? (
						<Container>
							<Flex gap="space.075" alignItems="center" xcss={labelContainerStyles}>
								<span
									ref={innerRef}
									data-test-id="content-item-label"
									// webitems sometimes have html (for keyboard shortcuts)
									dangerouslySetInnerHTML={{
										__html: label.replace(/[0-9]/g, '').replace(/[{()}]/g, ''),
									}}
								/>
								{showBetaLozenge && (
									<Lozenge appearance="new">
										<FormattedMessage {...i18n.lozengeBetaText} />
									</Lozenge>
								)}
							</Flex>
							{label.replace(/\D/g, '').length > 0 ? (
								<Box xcss={badgeContainerStyles}>
									<Badge>{label.replace(/\D/g, '')}</Badge>
								</Box>
							) : null}
						</Container>
					) : (
						label
					)}
				</DropdownItem>
			</div>
		);
	}
}

type ContentToolsItemClassProps = {
	id: string | null;
	innerRef: LegacyRef<HTMLElement>;
	label: ReactNode;
	onMouseEnter?: MouseEventHandler;

	// Pass-through props for DropdownItem:
	className?: string;
	url?: DropdownItemProps['href'] | null;
	showBetaLozenge?: boolean;
} & Pick<DropdownItemProps, 'isDisabled' | 'onClick' | 'testId'>;

export const ContentToolsItem = React.forwardRef(
	(
		props: Omit<ContentToolsItemClassProps, 'innerRef'>,
		ref: ContentToolsItemClassProps['innerRef'],
	) => <ContentToolsItemClass innerRef={ref} {...props} />,
);
ContentToolsItem.displayName = 'ContentToolsItem';
